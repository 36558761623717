import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import {ContactenosService} from '../Servicios/Contactenos/contactenos.service';

@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.css']
})
export class ContactenosComponent implements OnInit {
  /*
    Componente encargado de generar el formulario de contacto
  */
    validateForm: FormGroup; // Constante usada por Antd
    titulo; // Constante que contendra el titulo de la pagina para enviarselo al componente header
    ciudades = [];
    asunto = [];

    constructor(
        private fb: FormBuilder ,
        private message: NzNotificationService ,
        private contactenosService: ContactenosService
    ) {
        this.titulo = 'Contáctenos'; // Genero el titulo de la página para enviarlo al componente Header
        // Configuramos la posición donde se mostraran los diferentes mensajes
        this.message.config({
          nzPlacement: 'bottomLeft'
        });


        this.contactenosService.getCiudad().pipe(first()).subscribe( data => {
          this.ciudades = data;
        }, error => {
            console.log(error); // Imprimimos en consola el error
        });
        this.contactenosService.getAsunto().pipe(first()).subscribe( data => {
          this.asunto = data;
          console.log(this.asunto);
        }, error => {
            console.log(error); // Imprimimos en consola el error
        });
    }

    ngOnInit() {
      // Set la variable validateForm para el envío del formulario reactivo del contactenos
      this.validateForm = this.fb.group({
        nombre: [ null, [ Validators.required ] ], // Campo Requerido
        apellido: [ null, [ Validators.required ] ], // Campo Requerido
        correo: [ null, [ Validators.required, Validators.email ] ], // Campo Requerido
        telefono: [ null, [ Validators.required ] ], // Campo Requerido
        asunto: [ null, [Validators.required ] ], // Campo Requerido
        ciudad: [ null, [Validators.required ] ], // Campo Requerido
        mensaje: [ null ] // Campo no Requerido
      });
    }
    submitForm(): void {
      // Recorremos la variable validateForm para validar los campos del formulario
      // tslint:disable-next-line:forin
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[ i ].markAsDirty();
        this.validateForm.controls[ i ].updateValueAndValidity();
      }
      // Detenemos el proceso si caso algo esta invalido
      if (this.validateForm.invalid) {
        return;
      }

      const datosFormulario = this.validateForm.value; // Generamos la constante que contiene los valores de los campos del formulario
      // Generamos la constante para enviarla al servicio
      const datos = {
        nombre: datosFormulario.nombre,
        apellido: datosFormulario.apellido,
        email: datosFormulario.correo,
        phoneNumber: datosFormulario.telefono,
        subject: datosFormulario.asunto,
        departmentId: datosFormulario.ciudad,
        comment: datosFormulario.mensaje,
        app: 'Yamaha', // Determinamos a donde pertenece el formulario de contacto
      };
      // Llamamos al servicio de contacto
      this.contactenosService.enviarFormulario(datos).pipe(first()).subscribe(data => {
        this.validateForm.reset(); // Limpiamos los campos del formulario
        // tslint:disable-next-line
        this.message.success('Toyosa S.A.', 'Su consulta ha sido enviada correctamente. Gracias por su tiempo, nos comunicaremos a la brevedad posible'); // Devolvemos el mensaje al cliente
      }, error => {
        this.message.error('Toyosa S.A.', 'Ha ocurrido un error , por favor intente nuevamente'); // Devolvemos el mensaje al cliente
      });
    }

}
