import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-drive',
  templateUrl: './test-drive.component.html',
  styleUrls: ['./test-drive.component.css']
})
export class TestDriveComponent implements OnInit {
  titulo;
  constructor() {
    this.titulo = 'Test Drive';
  }

  ngOnInit() {
  }

}
