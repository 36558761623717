import { Component, OnInit } from '@angular/core';
import { ModelosService } from '../Servicios/Modelos/modelos.service';

@Component({
  selector: 'app-slider-racing',
  templateUrl: './slider-racing.component.html',
  styleUrls: ['./slider-racing.component.css']
})
export class SliderRacingComponent implements OnInit {
  config;
  index;
  modelos = ['slide1-home-yamaha','slide2-home-yamaha','slide3-home-yamaha'];

  constructor() {
  }

  ngOnInit() {
    // Configuracion del Carrousel
    this.config = {
      direction: 'horizontal',
      slidesPerView: 'auto',
      loop: false,
      autoplay: true,
      effect: 'slide',
      speed: 3000,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    };
    this.index = 0;
  }

}
