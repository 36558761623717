import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import {ModelosService} from '../Servicios/Modelos/modelos.service';
import { Lightbox } from 'ngx-lightbox';

@Component({
    selector: 'app-motos',
    templateUrl: './motos.component.html',
    styleUrls: ['./motos.component.css']
})
export class MotosComponent implements OnInit {
  /* Componente dinámico que recibe el modelo_key y devuelve toda la información y contenido sobre el modelo */
    base_url; // Constante que obtiene la url actual
    titulo: string; // Constante que obtendra el titulo de la pagina
    nombreModelo: string; // Constante que guardara el nombre del modelo
    precioModelo: string; // Constante que guardara el precio del modelo
    sub_categoria: string; // Constante que guardara la sub categoria del modelo
    imagenesModelo; // Array que contendra todas las imagenes del modelo
    contenidoModelo = []; // Array que contendra la información del modelo
    especificacionesTecnicasModelo = []; // Array que contiene el key de las especificaciones tecnicas
    detalleEspecificacionesTecnicasModelo = []; // Array que contiene el valor de las especificaciones tecnicas
    parametroModelo: string; // Constante en la que se guardara el modelo_key
    seccionesEspeciales: boolean; // Variable que determina si se visualizaran las secciones euro y blue core
    seccionAdicional: boolean; // Variable que determina si se visualizaran las secciones galeria y video para xtz150
    seccionAdicionalfz25: boolean; // Variable que determina si se visualizaran las secciones galeria y video para xtz150
    seccionxtz150: boolean; // Variable que determina si se quita el slider si ingresa a xtz150
    enlaceCotizador: string; // Constante en la cual se armara la url para cotizar el modelo
    config; // Constante de configuración del swiper
    index; // Variable que determina en que indice inicia el swiper
    private _album = []; //Para la galeria de imagenes xtz150
    private _albumfz25 = []; //Para la galeria de imagenes fz25

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modelosServices: ModelosService,
        private _lightbox: Lightbox
    ) {
        this.base_url = window.location.href; // Obtenemos la url actual
        this.parametroModelo = this.route.snapshot.paramMap.get('modelo'); // Obtenemos el modelo_key
        this.titulo = 'Motos - ' + this.parametroModelo.toUpperCase(); // Generamos el titulo de la página para enviarsela al header
        // Llamamos al servicio enviandole el modelo_key
        this.modelosServices.getModelo(this.parametroModelo).pipe(first()).subscribe( data => {
            this.nombreModelo = data.nombre_modelo; // Guardamos el nombre del modelo
            this.precioModelo = data.precio; // Guardamos el precio del modelo
            this.sub_categoria = data.sub_categoria;// Guardamos la sub categoría del modelo
            this.imagenesModelo = data.imagenes_web; // Guardamos las imagenes del modelo
            this.contenidoModelo = data.contenido; // Guardamos el contenido del modelo
            // tslint:disable-next-line:max-line-length
            this.especificacionesTecnicasModelo = Object.keys(data.caracteristicas); // Obtenemos solo las key de las especificaciones tecnicas
            this.detalleEspecificacionesTecnicasModelo = data.caracteristicas; // Obtenemos las caracteristicas tecnicas del modelo
            // tslint:disable-next-line:max-line-length
            const categoriaModelo = data.sub_categoria.toLowerCase().split(' ').join('-'); // Generamos la constante con la categoria del modelo
            // tslint:disable-next-line:max-line-length
            this.enlaceCotizador = 'https://cotizador.yamaha.bo/cotizar/' + categoriaModelo + '/' + this.nombreModelo.toLowerCase().split(' ').join('-'); // Generamos la url del cotizador
        }, error => {
            console.log(error); // Imprimimos en consola el error
        });
        // Verificamos si el modelo es la fz blue core o la fz 2.5 para visualizar las secciones especiales
        if (this.parametroModelo === 'fz-blue-core' || this.parametroModelo === 'fz2.5') {
            this.seccionesEspeciales = true;
        } else {
            this.seccionesEspeciales = false;
        }
        // Verificamos si el modelo es la xtz150 para visualizar las imagenes adicionales
        if (this.parametroModelo === 'xtz150') {
            this.seccionAdicional = true;
            this.seccionxtz150 = false;
        } else {
            this.seccionAdicional = false;
            this.seccionxtz150 = true;
        }
        // Verificamos si el modelo es la xtz150 para visualizar las imagenes adicionales
        if (this.parametroModelo === 'fz25') {
            this.seccionAdicionalfz25 = true;
        } else {
            this.seccionAdicionalfz25 = false;
        }

        // For para la galeria de xtz150
        for (let i = 1; i <= 16; i++) {
            const src = '../../assets/images/motos/xtz150/galeria-' + i + '.jpg';
            const caption = 'XTZ150 - Yamaha Bolivia (' + i + ')';
            const thumb = '../../assets/images/motos/xtz150/galeria-' + i + '.jpg';
            const album = {
               src: src,
               caption: caption,
               thumb: thumb
            };
            this._album.push(album);
        }

         // For para la galeria de fz25
         for (let i = 1; i <= 20; i++) {
            const src = '../../assets/images/motos/fz25/galeria-' + i + '.jpg';
            const caption = 'FZ25 - Yamaha Bolivia (' + i + ')' ;
            const thumb = '../../assets/images/motos/fz25/galeria-' + i + '.jpg';
            const albumfz25 = {
               src: src,
               caption: caption,
               thumb: thumb
            };
            this._albumfz25.push(albumfz25);
        }
    }

    ngOnInit() {
      // Configuramos el swiper en la constante config
        this.config = {
            direction: 'horizontal',
            slidesPerView: 'auto',
            loop: true,
            autoplay: true,
            effect: 'slide', // Efecto por defecto
            speed: 3000,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        };
        this.index = 0; // Inicializamos el indice en la primer posicion
    }

    open(index: number): void {
        // open lightbox
        this._lightbox.open(this._album, index);
    }

    openfz25(index: number): void {
        // open lightbox
        this._lightbox.open(this._albumfz25, index);
    }
     
    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }
}
