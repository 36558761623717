import { Component, OnInit } from '@angular/core';
import { AtvUtvService } from '../Servicios/Modelos/atv-utv.service';

@Component({
  selector: 'app-categoria-utv',
  templateUrl: './categoria-utv.component.html',
  styleUrls: ['./categoria-utv.component.css']
})
export class CategoriaUtvComponent implements OnInit {
  // Componente que contiene el listado de UTV y ATV
  titulo;
  modelos = [];

  constructor(private modelosAtvUtvServices: AtvUtvService) {
    this.titulo = 'Atv & Utv';
    this.modelos.push(this.modelosAtvUtvServices.getAllModelsAtvUtv());
    console.log(this.modelos[0]);
  }

  ngOnInit() {
  }

}
