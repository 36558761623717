import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ModelosService } from '../Servicios/Modelos/modelos.service';

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { CiudadService } from './../Servicios/Ciudad/ciudad.service';
import { CotizarService } from './../Servicios/Cotizar/cotizar.service';

@Component({
  selector: 'app-comparador-modelos',
  templateUrl: './comparador-modelos.component.html',
  styleUrls: ['./comparador-modelos.component.css']
})
export class ComparadorModelosComponent implements OnInit {
  /*
    Componente encargado de generar la comparación de 2 diferentes modelos,
     mostrando en una tabla las características de cada modelo
    permitiendo al final generar una cotización de los mismos
  */
  titulo: string; // Constante que contendra el titulo de la pagina para enviarselo al componente header
  modelos = []; // Constante que cargara todos los modelos que vengan en el servicio
  modelo1; // Variable que obtendra el valor del modelo escogido en el primer select
  nombreModelo1: string; // Variable en la cual se guardara el nombre del modelo1
  imagenModelo1; // Variable en la cual se guardadara la imagen del modelo1
  caracteristicasModelo1 = []; // Variable en la cual se guardaran las caracteristicas del modelo1
  modelo2; // Variable que obtendra el valor del modelo escogido en el segundo select
  nombreModelo2: string; // Variable en la cual se guardara el nombre del modelo2
  imagenModelo2; // Variable en la cual se guardadara la imagen del modelo2
  caracteristicasModelo2 = []; // Variable en la cual se guardaran las caracteristicas del modelo2
  nombreCategorias = []; // Constante en la cual se guardaran todas las categorias de las caracteristicas tecnicas de las motos
  categoriaModelo1: string; // Variable en la cual se guardara la categoria del modelo1
  enlaceModelo1: string; // Variable en la cual se generará el enlace a la página del modelo1
  categoriaModelo2: string; // Variable en la cual se guardara la categoria del modelo2
  enlaceModelo2: string; // Variable en la cual se generará el enlace a la página del modelo2

  // Cotizador
  ciudades = []; // Constante en la cual se guardara el listado de todas las ciudades obtenidas del servicio
  visible: boolean; // Variable que determina si el modal esta visible
  validateForm: FormGroup; // Constante usada por Antd
  respuestas; // Constante que sera enviada al servicio de cotización
  myRecaptcha: boolean; // Variable que determina el captcha
  window; // Constante que contendra el metodo Window
  modeloCotizar; // Constante que contendra el id del modelo que se esta cotizando
  nombreModeloCotizar; // Constante que contendra el nombre del modelo que se esta cotizando
  constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modelosService: ModelosService,
        private fb: FormBuilder,
        private message: NzMessageService,
        private cotizar: CotizarService,
        private ciudad: CiudadService,
  ) {
        this.titulo = 'Comparador de Modelos'; // Genero el titulo de la página para enviarlo al componente Header
        // Llamado al servicio para obtener todos los modelos
        this.modelosService.getAll().pipe(first()).subscribe(data => {
          // tslint:disable-next-line:max-line-length
          // Si el servicio devuelve datos los guardamos en una constante local y luego la recorremos para obtener los modelos y guardarlo en la constante modelos
            const datos = data;
            // tslint:disable-next-line:forin
            for (const datosKey in datos) {
                datos[datosKey].map((index) => {
                    this.modelos.push(index);
                });
            }
        }, error => {
            console.log(error);
        });
        // Llamado al servicio para obtener todas las ciudades
        this.ciudad.obtener().pipe(first()).subscribe(
          data => {
            this.ciudades = data; // Guardamos la data en la constante de ciudades
          }, error => {
            console.log('error');
          }
        );
  }
  // Función que se dispara al seleccionar un modelo en el primer select
  getModelo1() {
        // tslint:disable-next-line:max-line-length
        const modelo = this.modelos[this.modelo1]; // Se obtiene la información general del modelo seleccionado introduciendo en la constante modelos como indice la variable modelo1
        this.nombreModelo1 = modelo.nombre_modelo; // Guardamos en la variable nombreModelo1 el nombre del modelo seleccionado
        this.imagenModelo1 = modelo.url_imagen; // Guardamos en la variable imagenModelo1 la imagen del modelo seleccionado
        // Llamado al servicio de modelo especifico para obtener todas sus características técnicas
        this.modelosService.getModelo(modelo.modelo_key).pipe(first()).subscribe(data => {
            this.caracteristicasModelo1 = Object.values(data.caracteristicas); // Guardamos el valor de cada caracteristica tecnica
            this.nombreCategorias = Object.keys(data.caracteristicas); // Guardamos el nombre de todas las caracteristicas tecnicas
            // tslint:disable-next-line:max-line-length
            this.categoriaModelo1 = data.sub_categoria.toLowerCase().split(' ').join('-'); // Obtenemos la categoría del modelo y la guardamos en la variable categoriaModelo1
            // tslint:disable-next-line:max-line-length
            this.enlaceModelo1 = '/motos/' + this.categoriaModelo1 + '/' + this.nombreModelo1.toLowerCase().split(' ').join('-'); // Generamos el enlace a la página del modelo1
        }, error => {
            console.log(error);
        });
  }
  // Función que se dispara al seleccionar un modelo en el segundo select
  getModelo2() {
        // tslint:disable-next-line:max-line-length
        const modelo = this.modelos[this.modelo2]; // Se obtiene la información general del modelo seleccionado introduciendo en la constante modelos como indice la variable modelo1
        this.nombreModelo2 = modelo.nombre_modelo; // Guardamos en la variable nombreModelo1 el nombre del modelo seleccionado
        this.imagenModelo2 = modelo.url_imagen; // Guardamos en la variable imagenModelo1 la imagen del modelo seleccionado
        // Llamado al servicio de modelo especifico para obtener todas sus características técnicas
        this.modelosService.getModelo(modelo.modelo_key).pipe(first()).subscribe(data => {
            this.caracteristicasModelo2 = Object.values(data.caracteristicas); // Guardamos el valor de cada caracteristica tecnica
            this.nombreCategorias = Object.keys(data.caracteristicas); // Guardamos el nombre de todas las caracteristicas tecnicas
            // tslint:disable-next-line:max-line-length
            this.categoriaModelo2 = data.sub_categoria.toLowerCase().split(' ').join('-'); // Obtenemos la categoría del modelo y la guardamos en la variable categoriaModelo2
            // tslint:disable-next-line:max-line-length
            this.enlaceModelo2 = '/motos/' + this.categoriaModelo2 + '/' + this.nombreModelo2.toLowerCase().split(' ').join('-'); // Generamos el enlace a la página del modelo2
        }, error => {
            console.log(error);
        });
  }
  // Función que se dispara al dar click en cotizar, recibe como parametro el id del select
  visibleModal(id: number) {
    // Preguntamos si le dieron click al modelo1
    if (id === 1) {
      // tslint:disable-next-line:max-line-length
      const modelo = this.modelos[this.modelo1].modelo_key; // Se obtiene la información general del modelo seleccionado introduciendo en la constante modelos como indice la variable modelo1
      // Llamado al servicio para obtener el id y el nombre del modelo seleccionado
      this.modelosService.getModelo(modelo).pipe(first()).subscribe(data => {
        this.modeloCotizar = data.id_detalle_modelo;
        this.nombreModeloCotizar = this.nombreModelo1;
        this.visible = true;
      });
    } else {
      // tslint:disable-next-line:max-line-length
      const modelo = this.modelos[this.modelo2].modelo_key; // Se obtiene la información general del modelo seleccionado introduciendo en la constante modelos como indice la variable modelo1
      // Llamado al servicio para obtener el id y el nombre del modelo seleccionado
      this.modelosService.getModelo(modelo).pipe(first()).subscribe(data => {
        this.modeloCotizar = data.id_detalle_modelo;
        this.nombreModeloCotizar = this.nombreModelo2;
        this.visible = true;
      });
    }
  }
  // Funcion que cierra el modal
  cancelModal() {
    this.visible = false;
  }

  ngOnInit() {
    this.window = window; // Guardamos el metodo window en la constante
    this.myRecaptcha = false; // Set la variable del captcha como false
    // Set la variable validateForm para el envío del formulario reactivo del cotizador
    this.validateForm = this.fb.group({
      nombre: [ null, [ Validators.required] ], // El campo nombre es obligatorio
      telefono: [null, [Validators.required]], // El campo telefono es obligatorio
      correo: [null, [Validators.required] ], // El campo correo es obligatorio
      ciudad: [null, [Validators.required] ], // El campo ciudad es obligatorio
      carnet: [null], // El campo carnet no es obligatorio y puede ir vacio
      modelo: [null],
      descripcion: [null], // El campo descripcion no es obligatorio
      emision: [null] // El campo emision no es obligatorio
    });
  }
  // Función que se dispara al enviar el formulario del cotizador
  submitForm(): void {
    // Recorremos la variable validateForm para validar los campos del formulario
    // tslint:disable-next-line:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
    // Detenemos el proceso si caso algo esta invalido
    if (this.validateForm.invalid) {
      return;
    }

    this.visible = true;
    const origenAplicacion = 'Comparador Web Yamaha'; // Generamos la constante que determina el origen de la cotizacion
    const idCiudad = this.validateForm.controls.ciudad.value; // Guardamos el valor del id de la ciudad
    let correo = ''; // Generamos la variable correo como vacía
    // Verificamos que el campo correo no este vacío
    if (this.validateForm.controls.correo.value !== null) {
      correo = this.validateForm.controls.correo.value; // Guardamos el campo correo en la variable correo
    }
    // Guardamos las respuestas como se enviaran al servicio
    this.respuestas = {
      fullName: this.validateForm.controls.nombre.value,
      phoneNumber: this.validateForm.controls.telefono.value,
      email: correo,
      departmentId: idCiudad,
      vehicleId: this.modeloCotizar,
      ci: this.validateForm.controls.carnet.value,
      emision: this.validateForm.controls.emision.value,
      comment: this.validateForm.controls.descripcion.value,
      app: origenAplicacion,
    };
    // Verificamos si el captcha esta tickeado
    if (this.myRecaptcha) {
      this.cotizar.enviar(this.respuestas) // Procedemos al envío del formulario
      .pipe(first())
      .subscribe(
        data => {
          // tslint:disable-next-line:max-line-length
          // Verificamos si el servicio nos devuelve algun error al validar los campos, de haberlo recorremos la data para determinar el error y devolver un mensaje al usuario
          if (data.error) {
            data.error.map((index) => {
              switch (index) {
                case 'The full name must be between 2 and 50 characters.':
                  this.message.warning('El nombre debe tener de 2 a 50 caracteres');
                  break;
                case 'The email must be a valid email address.':
                  this.message.warning('Correo Invalido');
                  break;
                case 'The email must be between 5 and 128 characters.':
                  this.message.warning('El correo debe tener entre 5 a 128 caracteres');
                  break;
                case '(phone number) debe ser un número.':
                  this.message.warning('El campo teléfono solo acepta números');
                  break;
                case 'The phone number must be between 7 and 8 digits.':
                  this.message.warning('El telefono debe tener entre 7 a 8 digitos');
                  break;
                default:
                  this.message.warning(index);
                  break;
              }
            });
          } else {
            // De no haber errores generamos el evento dataLayer para capturarlo con el tag manager
            this.window.dataLayer = this.window.dataLayer || [];
                        this.window.dataLayer.push({
                            'event': 'cotizacionSuccess',
                            'registrationCountry': 'Bolivia',
                            'plan': 'Norway',
                            'gtm.elementText': 'enviarExitoso'
                        });
              // tslint:disable-next-line:max-line-length
              localStorage.setItem('cotizacion-yamaha', JSON.stringify(data)); // Almacenamos en un local storage la data que envia el servidor
              localStorage.setItem('datosCliente', JSON.stringify(this.respuestas)); // Almacenamos los datos del cliente
              this.router.navigate(['/']); // Redireccionamos al home
          }

          this.visible = false; // cerramos el modal

        },
        error => {
          this.message.error('Ha ocurrido un error'); // En caso que exista errores devolvemos el mensaje al cliente
          this.visible = false; // Ocultamos el modal
          console.log(error); // Imprimimos el error en consola
        }
      );
    } else {
      this.message.warning('CAPTCHA invalido'); // Si el captcha no esta tickeado devolvemos el mensaje al cliente
    }
  }

}
