import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelosService } from '../Servicios/Modelos/modelos.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-linea-fuerza',
  templateUrl: './linea-fuerza.component.html',
  styleUrls: ['./linea-fuerza.component.css']
})
export class LineaFuerzaComponent implements OnInit {
  /* Componente que genera la página que muestra todas las categorias de motocicletas */
  titulo;
  constructor() {
    this.titulo = 'Linea de Fuerza';
  }

  ngOnInit() {
  }

}
