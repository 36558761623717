import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CitasService {

  constructor(private http: HttpClient) { 
   
  }
  // Servicio que obtiene todos los modelos
  getAll() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/yamaha/motos`;
    return this.http.get<any>(url, httpOptions);
  }

  enviarFormulario (datos: any) {
    const url = `${environment.base_url}/vehiculo/cita`;
    return this.http.post<any>(url, datos);
  }
}
