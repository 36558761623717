import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ColorearServicesService {

  constructor(private http: HttpClient) { }
  // Servicio que envia el formulario, recibe un objeto con la información de los campos
  colorear () {
    const url = `${environment.base_url}/yamaha/motos/colorea`;
    return this.http.get<any>(url);
  }
}
