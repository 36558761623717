import { Component, OnInit } from '@angular/core';
import { MotosCiudadService } from '../Servicios/Modelos/motos-ciudad.service';

@Component({
  selector: 'app-categoria-motos-ciudad',
  templateUrl: './categoria-motos-ciudad.component.html',
  styleUrls: ['./categoria-motos-ciudad.component.css']
})
export class CategoriaMotosCiudadComponent implements OnInit {
  // Componente que contiene el listado de motos de Ciudad
  titulo;
  modelos = [];

  constructor(private modelosCiudadServices: MotosCiudadService) {
    this.titulo = 'Motos - Ciudad';
    this.modelos.push(this.modelosCiudadServices.getAllModelsCity());
    console.log(this.modelos[0]);
  }

  ngOnInit() {
  }

}
