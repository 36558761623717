import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-mantenimiento',
  templateUrl: './mantenimiento.component.html',
  styleUrls: ['./mantenimiento.component.css']
})
export class MantenimientoComponent implements AfterViewInit {
  @ViewChild('thumbsSlider') thumbsSlider?: SwiperComponent;
  @ViewChild('mobileSlider') mobileSlider?: SwiperComponent;

  // Configuración de thumbails del slider principal
  public thumbsSliderConfig: SwiperConfigInterface = {
    slidesPerView: 4,
    spaceBetween: 10,
    pagination: false,
    slideActiveClass: 'activeThumbModel',
    slideToClickedSlide: true,
    navigation: true,
    observer: true,
    loopedSlides: 4
  };

  // Configuración de mobile del slider principal
  public mobileSliderConfig: SwiperConfigInterface = {
    slidesPerView: 2,
    spaceBetween: 10,
    pagination: false,
    slideActiveClass: 'activeMobileModel',
    slideToClickedSlide: true,
    navigation: true,
    observer: true,
    loopedSlides: 3
  };

  titulo;

  index; // Variable que determina en que indice inicia el swiper
  width: number;

  constructor() {
    this.titulo = 'Servicio y Mantenimiento';
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // Inicializando slider principal y thumbails del slider principal
    this.thumbsSlider.directiveRef.swiper().controller.control = this.thumbsSlider.directiveRef.swiper();
    this.mobileSlider.directiveRef.swiper().controller.control = this.mobileSlider.directiveRef.swiper();

  }
}
