import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MotosCompetenciaService {

  modelosCompetition = {
    nombre: [
      'PW50',
      /*'YZ65',*/
      'YZ450',
      /*'YZ450FX',*/
      'YZ250',
      'WR250'
      /*'WR450'*/
    ],
    descripcion: [
      'pw50 lorem ipsum',
      /*'yz65 lorem ipsum',*/
      'yz450 lorem ipsum',
      /*'yz450fx lorem ipsum',*/
      'yz250 lorem ipsum',
      'wr250 lorem ipsum'
      /*'wr450 lorem ipsum'*/
    ],
    imagen: [
      'banner-pw50',
      /*'banner-yz65',*/
      'banner-yz450',
      /*'banner-yz450fx',*/
      'banner-yz250',
      'banner-wr250'
      /*'banner-wr450'*/
    ],
    ruta: [
      '/pw50',
      /*'/yz65',*/
      '/yz450',
      /*'/yz450fx',*/
      '/yz250',
      '/wr250'
      /*'/wr450'*/
    ]
  };

  constructor() { }
  
  // Obtener todos los modelos de motos trabajo
  getAllModelsCompetition() {
    return this.modelosCompetition;
  }

}
