import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-motos-dashboard',
  templateUrl: './motos-dashboard.component.html',
  styleUrls: ['./motos-dashboard.component.css']
})
export class MotosDashboardComponent implements OnInit {
  /* Componente que genera la página que muestra todas las categorias de motocicletas */
  titulo;
  constructor() {
    this.titulo = 'Motos';
  }

  ngOnInit() {
  }

}
