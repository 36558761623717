import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MotostrabajoService {
  modelosTrabajo = {
    nombre: [
      'AG200',    
      /*'YBR125ED',*/
      'YB125'
      /*'YBR125ZR'*/
    ],
    descripcion: [
      'ag200 lorem ipsum',
      /*'ybr125ed lorem ipsum',*/
      'yb125 lorem ipsum'
      /*'ybr125zr lorem ipsum'*/
    ],
    imagen: [
      'banner-ag200',
      /*'banner-ybr125ed',*/
      'banner-yb125'
      /*'banner-yb125zr'*/
    ],
    ruta: [
      '/ag200',  
      /*'/ybr125ed',*/
      '/yb125'
      /*'/ybr125zr'*/
    ]
  };

  constructor() { }
   // Obtener todos los modelos de motos trabajo
   getAllModelsWork() {
    return this.modelosTrabajo;
  }
}
