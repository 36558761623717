import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categoria-lf-generadores',
  templateUrl: './categoria-lf-generadores.component.html',
  styleUrls: ['./categoria-lf-generadores.component.css']
})
export class CategoriaLfGeneradoresComponent implements OnInit {

  // Componente que contiene el listado de Generadores
  titulo;
  constructor() {
    this.titulo = 'Generadores';
  }

  ngOnInit() {
  }

}
