import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import {ContactenosService} from '../Servicios/Contactenos/contactenos.service';
import { CitasService } from '../Servicios/citas/citas.service';

@Component({
  selector: 'app-agendar-cita',
  templateUrl: './agendar-cita.component.html',
  styleUrls: ['./agendar-cita.component.css']
})
export class AgendarCitaComponent implements OnInit {
/*
    Componente encargado de generar el formulario de contacto
  */
 validateForm: FormGroup; // Constante usada por Antd
 titulo; // Constante que contendra el titulo de la pagina para enviarselo al componente header
 listadoModelos;
 constructor(
     private fb: FormBuilder ,
     private message: NzNotificationService ,
     private citasServi: CitasService
 ) {
     this.titulo = 'Contáctenos'; // Genero el titulo de la página para enviarlo al componente Header
     // Configuramos la posición donde se mostraran los diferentes mensajes
     this.message.config({
       nzPlacement: 'bottomLeft'
     });

     this.citasServi.getAll().pipe(first()).subscribe(data => {
        console.log(data);
        this.listadoModelos = data;
      }, error => {
        this.message.error('Toyosa S.A.', 'Ha ocurrido un error al obtener los datos'); // Devolvemos el mensaje al cliente
      });
 }

 ngOnInit() {
   // Set la variable validateForm para el envío del formulario reactivo del contactenos
   this.validateForm = this.fb.group({
     nombre: [ null, [ Validators.required ] ], // Campo Requerido
     apellido: [ null, [ Validators.required ] ], // Campo Requerido
     modelo: [ null, [ Validators.required ] ], // Campo Requerido
     anio: [ null, [Validators.required ] ], // Campo Requerido
     mantenimiento: [ null, [Validators.required ] ], // Campo Requerido
     telefono: [ null, [Validators.required ] ], // Campo Requerido
     placa: [ null, [Validators.required ] ], // Campo Requerido
     mensaje: [ null ] // Campo no Requerido
   });
 }
 submitForm(): void {
   // Recorremos la variable validateForm para validar los campos del formulario
   // tslint:disable-next-line:forin
   for (const i in this.validateForm.controls) {
     this.validateForm.controls[ i ].markAsDirty();
     this.validateForm.controls[ i ].updateValueAndValidity();
   }
   // Detenemos el proceso si caso algo esta invalido
   if (this.validateForm.invalid) {
     return;
   }

   const datosFormulario = this.validateForm.value; // Generamos la constante que contiene los valores de los campos del formulario
   // Generamos la constante para enviarla al servicio
   const datos = {
     nombre: datosFormulario.nombre,
     apellido: datosFormulario.apellido,
     phoneNumber: datosFormulario.telefono,
     email:"",
     empresa:"",
     id_modelo: datosFormulario.modelo,
     id_marca:1,
     year: datosFormulario.anio,
     mantenimiento: datosFormulario.mantenimiento,
     comentario: datosFormulario.mensaje,
     placa: datosFormulario.placa
   };
   // Llamamos al servicio de contacto
   this.citasServi.enviarFormulario(datos).pipe(first()).subscribe(data => {
     this.validateForm.reset(); // Limpiamos los campos del formulario
     // tslint:disable-next-line
     this.message.success('Toyosa S.A.', 'Su cita fué registrada correctamente. Gracias por su tiempo, nos comunicaremos a la brevedad posible'); // Devolvemos el mensaje al cliente
   }, error => {
     this.message.error('Toyosa S.A.', 'Ha ocurrido un error , por favor intente nuevamente'); // Devolvemos el mensaje al cliente
   });
 }

}

