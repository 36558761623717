import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cta-menu',
  templateUrl: './cta-menu.component.html',
  styleUrls: ['./cta-menu.component.css']
})
export class CtaMenuComponent implements OnInit {
  /* Componente que genera los cta para los modelos */
  @Input() enlaceCotizador; // Recibimos el enlace del modelo para enviarlos al cotizador
  constructor() { }

  ngOnInit() {
  }

}
