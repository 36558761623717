import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarjetas-flotantes',
  templateUrl: './tarjetas-flotantes.component.html',
  styleUrls: ['./tarjetas-flotantes.component.css']
})
export class TarjetasFlotantesComponent implements OnInit {
    /* Mostrara u Ocultara los botones de cada tarjeta incluyendo el fondo obscuro */
    botonesMotos = false;
    botonesUtv = false;
    botonesLinea = false;
    botonesAccesorios = false;

    constructor() { }

    // Muestra u Oculta botones de la tarjeta de Motos
    mostrarBotonesMotos() {
        if (this.botonesMotos) {
            this.botonesMotos = false;
        } else {
            this.botonesMotos = true;
        }
    }

    // Muestra u Oculta botones de la tarjeta de Utv
    mostrarBotonesUtv() {
        if (this.botonesUtv) {
            this.botonesUtv = false;
        } else {
            this.botonesUtv = true;
        }
    }

    // Muestra u Oculta botones de la tarjeta de Linea de Fuerza
    mostrarBotonesLinea() {
        if (this.botonesLinea) {
            this.botonesLinea = false;
        } else {
            this.botonesLinea = true;
        }
    }

  // Muestra u Oculta botones de la tarjeta de Accesorios
    mostrarBotonesAccesorios() {
        if (this.botonesAccesorios) {
            this.botonesAccesorios = false;
        } else {
            this.botonesAccesorios = true;
        }
    }

    ngOnInit() {
    }

}
