import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AtvUtvService {

  modelosAtvUtv = {
    nombre: [
      'YXZ1000R'
    ],
    descripcion: [
      'yxz1000r lorem ipsum'
    ],
    imagen: [
      'yxz1000r'
    ],
    ruta: [
      '/yxz1000r'
    ]
  };

  constructor() { }

  // Obtener todos los modelos de motos trabajo
  getAllModelsAtvUtv() {
    return this.modelosAtvUtv;
  }

}
