import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CotizarService {
  constructor(private http: HttpClient) { }
// Servicio que envia los datos del cliente para generar su cotización
  enviar(respuestas: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/cotizacion/vehiculo`;
    return this.http.post<any>(url, respuestas, httpOptions);
  }
}
