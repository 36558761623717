import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapa-sitio',
  templateUrl: './mapa-sitio.component.html',
  styleUrls: ['./mapa-sitio.component.css']
})
export class MapaSitioComponent implements OnInit {
  titulo;
  constructor() {
    this.titulo = 'Mapa del Sitio';
  }

  ngOnInit() {
  }

}
