import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.css']
})
export class SucursalesComponent implements OnInit {
  titulo: string;
  constructor() {
    this.titulo = 'Sucursales';
  }

  ngOnInit() {
  }

}
