import { Component, OnInit} from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  titulo;
  objBanner:any = [];
  width: number;
  urlCarpetaBannersDesktop;
  urlCarpetaBannersMobile;

  constructor(private meta: Meta) {
    this.width = innerWidth;
    this.urlCarpetaBannersDesktop = '../../assets/images/home/desktop';
    this.urlCarpetaBannersMobile = '../../assets/images/home/mobile';

    this.titulo = 'Inicio';
    // tslint:disable-next-line:max-line-length
    this.meta.updateTag({name: 'description', content: 'Cotiza tu YAMAHA favorita, TOYOSA S.A Distribuidor oficial de Yamaha en Bolivia.'});
    // tslint:disable-next-line:max-line-length
    this.meta.updateTag({ name: 'keywords', content: 'yamaha, moto, motos, motocicleta, motocicletas, toyota, toyosa, venta, accesorios, precios, cascos, sucursales, bolivia, santa cruz, beni, pando, cochabamba, tarija, potosi, oruro, la paz, sucre, montero, yacuiba, riberalta, puerto suarez'});
    this.objBanner = [
      {
        imagen: 'junio.webp',
        link: false,
        url: '',
        tipo: 'imagen',
        btn: false,
        nombre: 'GRANDES AVENTURAS PRECIOS PEQUEÑOS'
      }];
  }

  ngOnInit() {
  }

  onResize(event: any) {
    this.width = event.target.innerWidth;
  }

}
