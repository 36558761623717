import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MotostrabajoService } from '../Servicios/Modelos/motostrabajo.service';

@Component({
  selector: 'app-categoria-motos-trabajo',
  templateUrl: './categoria-motos-trabajo.component.html',
  styleUrls: ['./categoria-motos-trabajo.component.css']
})
export class CategoriaMotosTrabajoComponent implements OnInit {
  // Componente que contiene el listado de motos de Trabajo
  titulo;
  modelos = [];
 
  constructor( private modelosWorkServices: MotostrabajoService) {
    this.titulo = 'Motos - Trabajo';
    this.modelos.push(this.modelosWorkServices.getAllModelsWork());
    console.log(this.modelos[0]);
  }
  ngOnInit() {
  }

}
