import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cta-menu-linea-fuerza',
  templateUrl: './cta-menu-linea-fuerza.component.html',
  styleUrls: ['./cta-menu-linea-fuerza.component.css']
})
export class CtaMenuLineaFuerzaComponent implements OnInit {
  
  /* Componente que genera los cta para los modelos */
  @Input() enlaceCotizador; // Recibimos el enlace del modelo para enviarlos al cotizador
 
  constructor() { }

  ngOnInit() {
  }

}
