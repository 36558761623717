import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  mobile;
  visibleSubmenu;
  @Input() titulo;
  icono;
  logo = '../../assets/images/logo-v1.svg';
  submenuServicios = false;
  submenuNovedades = false;
  submenuNosotros = false;
  iconoServicios = 'down';
  iconoNovedades = 'down';
  iconoNosotros = 'down';

  constructor(
      private titleService: Title,
      private route: ActivatedRoute,
      private router: Router,
      private meta: Meta
  ) {

    const parametroModelo = this.route.snapshot.paramMap.get('modelo');
    const url = this.router.url;
    if (parametroModelo) {
        switch (parametroModelo) {
          case 'xt660z':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Prepárate para la aventura con la XT660Z Teneré, una motocicleta de doble propósito resistente, funcional y elegante. Te llevará a donde quieras.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'xt660z, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'XT660Z'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Prepárate para la aventura con la XT660Z Teneré, una motocicleta de doble propósito resistente, funcional y elegante. Te llevará a donde quieras.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'mt03':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'La poderosa y agresiva MT03 representa una nueva dirección en el diseño de motocicletas. De carácter versátil y estilo deportivo, se complementa perfectamente a cada aventura'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'mt03, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'XT660Z'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Prepárate para la aventura con la XT660Z Teneré, una motocicleta de doble propósito resistente, funcional y elegante. Te llevará a donde quieras.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'xt1200z':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Los viajes de aventuras requieren habilidad, valentía y resistencia, así como confianza en su máquina. ¡Confianza que sólo una XT1200Z Teneré te brinda!'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'xt1200z, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'XT1200Z'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Los viajes de aventuras requieren habilidad, valentía y resistencia, así como confianza en su máquina. ¡Confianza que sólo una XT1200Z Teneré te brinda!'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'ag200':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'La Yamaha AG 200 es una moto creada para hacer el trabajo duro en el campo perfecta para aquel emprendedor que quiera salir adelante.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'ag200, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'AG200'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Los viajes de aventuras requieren habilidad, valentía y resistencia, así como confianza en su máquina. ¡Confianza que sólo una XT1200Z Teneré te brinda!'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'ybr125g':
              // tslint:disable-next-line:max-line-length
              this.meta.updateTag({name: 'description', content: 'La Yamaha perfecta para el campo o la ciudad, una motocicleta doble propósito, ha incorporado elementos de mejor funcionamiento y rendimiento.'});
              // tslint:disable-next-line:max-line-length
              this.meta.updateTag({ name: 'keywords', content: 'ybr125g, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
              // tslint:disable-next-line:max-line-length
              this.meta.updateTag({ property: 'og:title', content: 'YBR125G'});
              // tslint:disable-next-line:max-line-length
              this.meta.updateTag({ property: 'og:description', content: 'La Yamaha perfecta para el campo o la ciudad, una motocicleta doble propósito, ha incorporado elementos de mejor funcionamiento y rendimiento.'});
              // tslint:disable-next-line:max-line-length
              this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'xtz125':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Diseño, resistencia y maniobrabilidad convierten a la XTZ125 en una gran opción para usarla en todo tipo de caminos.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'xtz125, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'xtz125'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Diseño, resistencia y maniobrabilidad convierten a la XTZ125 en una gran opción para usarla en todo tipo de caminos. '});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'fz-blue-core':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: '¡Mayor eficiencia y mejor desempeño con el Blue Core de Yamaha, una experiencia en una motocicleta de verdad'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'fz-blue-core, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'FZ FI / FZS FI'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: '¡Mayor eficiencia y mejor desempeño con el Blue Core de Yamaha, una experiencia en una motocicleta de verdad'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 't110c':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'La T110C permite un consumo mínimo de combustible, brinda mayor comodidad y equilibrio que se traducen en seguridad.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 't110c, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'T110C'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'La T110C permite un consumo mínimo de combustible, brinda mayor comodidad y equilibrio que se traducen en seguridad.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'xv250':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Con un peso ligero, una excelente distancia del asiento al suelo y un poderoso motor hacen de la XV250 la mejor motocicleta para la ciudad.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'xv250, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'XV250'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Con un peso ligero, una excelente distancia del asiento al suelo y un poderoso motor hacen de la XV250 la mejor motocicleta para la ciudad.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'yz450':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Diseñada para los apasionados del off road y las experiencias extremas, la YZ450 es una máquina potente que marca la diferencia.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'yz450, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'YZ450'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Diseñada para los apasionados del off road y las experiencias extremas, la YZ450 es una máquina potente que marca la diferencia.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'yz250':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Su capacidad de respuesta y el destacado rendimiento de motor, junto con el diseño compacto y ligero de chasis, convierten a la YZ250 en una verdadera competidora.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'yz250, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'YZ250'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Su capacidad de respuesta y el destacado rendimiento de motor, junto con el diseño compacto y ligero de chasis, convierten a la YZ250 en una verdadera competidora.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'wr250':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'La Yamaha WR250 está pensada para quienes disfrutan la naturaleza, quieren dar lo mejor de sí, y sobre todo, ¡quieren ganar!'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'wr250, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'WR250'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'La Yamaha WR250 está pensada para quienes disfrutan la naturaleza, quieren dar lo mejor de sí, y sobre todo, ¡quieren ganar!'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'wr450':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Potencia y resistencia se unieron para crear la WR450, una Yamaha especialmente equipada para que los pilotos de enduro.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'wr450, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'WR450'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Potencia y resistencia se unieron para crear la WR450, una Yamaha especialmente equipada para que los pilotos de enduro.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'yfm450wad':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Utilidad y aventura, una combinación perfecta para aquellos que quieren salir de su zona de confort.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'yfm450wad, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'YFM450WAD'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Utilidad y aventura, una combinación perfecta para aquellos que quieren salir de su zona de confort.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:url', content: 'https://web-yamaha.toyosa-test.com/atv-utv/yfm450wad'});
            break;
          case 'yxz1000r':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Excelente diseño, estabilidad y confort hace que tu experiencia en esta Yamaha sea digna de miles de aventuras.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'yxz1000r, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'YXZ1000R'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Excelente diseño, estabilidad y confort hace que tu experiencia en esta Yamaha sea digna de miles de aventuras.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case 'yzf450rse':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Hecho para campeones y aventureros capaces de ir por el primer premio con su Yamaha YZF450RSE.ng '});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'yzf450rse, yamaha, moto, motos, motocicleta, bolivia, santa cruz'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'YZF450RSE'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Hecho para campeones y aventureros capaces de ir por el primer premio con su Yamaha YZF450RSE'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          default:
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'La mejor marca de motocicletas del país con una trayectoria de calidad y eficiencia en rutas bolivianas.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'yamaha, bolivia, motos, motocicletas, moto'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'YAMAHA- BOLIVIA'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'La mejor marca de motocicletas del país con una trayectoria de calidad y eficiencia en rutas bolivianas.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
        }
      } else {
        console.log(url);
        switch (url) {
          case '/comparador-modelos':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Mejor diseño y rendimiento, mayor maniobrabilidad, conoce la calidad de una Yamaha vs los demás.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'comparar,yamaha, bolivia, motos, motocicletas, moto, precios, precios, modelos de motos'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'Comparador de Modelos - YAMAHA'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Mejor diseño y rendimiento, mayor maniobrabilidad, conoce la calidad de una Yamaha vs los demás.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case '/cotizar':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Realiza tu cotización y ¡adquiere tu Yamaha!'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'cotizar,yamaha, bolivia, motos, motocicletas, moto, precios, precios, modelos de motos'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'Cotizador de Modelos - YAMAHA'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Realiza tu cotización y ¡adquiere tu Yamaha!'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          case '/repuestos':
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'Solicita tus repuestos Yamaha 100% originales y garantizados.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'repuestos,yamaha, bolivia, motos, motocicletas, moto, precios, precios, modelos de motos'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'Repuestos - YAMAHA'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'Solicita tus repuestos Yamaha 100% originales y garantizados.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
            break;
          default:
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({name: 'description', content: 'La mejor marca de motocicletas del país con una trayectoria de calidad y eficiencia en rutas bolivianas.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ name: 'keywords', content: 'yamaha, bolivia, motos, motocicletas, moto, precios, precios, modelos de motos'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:title', content: 'YAMAHA- BOLIVIA'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:description', content: 'La mejor marca de motocicletas del país con una trayectoria de calidad y eficiencia en rutas bolivianas.'});
            // tslint:disable-next-line:max-line-length
            this.meta.updateTag({ property: 'og:image', content: 'https://api-res.toyosa.com/images/yamaha/opengraph/ogyamaha.png'});
        }
      }


    // Detecta la resolución actual de la pantalla y activa o desactiva la versión mobile del header
    if (window.innerWidth > 1199) {
      this.mobile = false;
    } else {
      this.mobile = true;
      this.icono = 'unfold';
    }
    // Inicializa el submenu siempre oculto
    this.visibleSubmenu = false;
  }
  // Función que muestra ú oculta el submenu del header
  toggleSubmenu() {
    if (this.visibleSubmenu) {
      this.icono = 'unfold';
      this.visibleSubmenu = false;
    } else {
      this.icono = 'fold';
      this.visibleSubmenu = true;
    }
  }

  toggleSubmenuServicios() {
    if (this.submenuServicios) {
      this.iconoServicios = 'down';
      this.submenuServicios = false;
    } else {
      this.iconoServicios = 'up';
      this.submenuServicios = true;
      // Escondemos los otros submenus
      this.iconoNovedades = 'down';
      this.submenuNovedades = false;
      this.iconoNosotros = 'down';
      this.submenuNosotros = false;
    }
  }
  toggleSubmenuNovedades() {
    if (this.submenuNovedades) {
      this.iconoNovedades = 'down';
      this.submenuNovedades = false;
    } else {
      this.iconoNovedades = 'up';
      this.submenuNovedades = true;
      // Escondemos los otros submenus
      this.iconoServicios = 'down';
      this.submenuServicios = false;
      this.iconoNosotros = 'down';
      this.submenuNosotros = false;
    }
  }
  toggleSubmenuNosotros() {
    if (this.submenuNosotros) {
      this.iconoNosotros = 'down';
      this.submenuNosotros = false;
    } else {
      this.iconoNosotros = 'up';
      this.submenuNosotros = true;
      // Escondemos los otros submenus
      this.iconoServicios = 'down';
      this.submenuServicios = false;
      this.iconoNovedades = 'down';
      this.submenuNovedades = false;
    }
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    // Setear el Titulo
    this.titleService.setTitle(this.titulo + ' | Yamaha Bolivia - Toyosa S.A');
  }

}
