import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelosService } from '../Servicios/Modelos/modelos.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-htecnicas-lineafuerza',
  templateUrl: './htecnicas-lineafuerza.component.html',
  styleUrls: ['./htecnicas-lineafuerza.component.css']
})
export class HtecnicasLineafuerzaComponent implements OnInit {
  /* Componente dinámico que recibe el modelo_key y devuelve toda la información y contenido sobre el modelo */
  base_url; // Constante que obtiene la url actual
  titulo: string; // Constante que obtendra el titulo de la pagina
  nombreModelo: string; // Constante que guardara el nombre del modelo
  precio: string; // Constante que guardara el nombre del modelo
  imagenesModelo; // Array que contendra todas las imagenes del modelo
  contenidoModelo = []; // Array que contendra la información del modelo
  especificacionesTecnicasModelo = []; // Array que contiene el key de las especificaciones tecnicas
  detalleEspecificacionesTecnicasModelo = []; // Array que contiene el valor de las especificaciones tecnicas
  parametroModelo: string; // Constante en la que se guardara el modelo_key
  seccionesEspeciales: boolean; // Variable que determina si se visualizaran las secciones euro y blue core
  enlaceCotizador: string; // Constante en la cual se armara la url para cotizar el modelo
  enlaceVacio: "contactenos"; // Constante en la cual se armara la url para cotizar el modelo
  config; // Constante de configuración del swiper
  index; // Variable que determina en que indice inicia el swiper

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private modelosServices: ModelosService
  ) {
      this.base_url = window.location.href; // Obtenemos la url actual
      this.parametroModelo = this.route.snapshot.paramMap.get('modelo'); // Obtenemos el modelo_key
      this.titulo = 'Linea de Fuerza - ' + this.parametroModelo.toUpperCase(); // Generamos el titulo de la página para enviarsela al header
      // Llamamos al servicio enviandole el modelo_key
      this.modelosServices.getModelosLF(this.parametroModelo).pipe(first()).subscribe( data => {
          this.nombreModelo = data.nombre_modelo; // Guardamos el nombre del modelo
          this.precio = data.precio; // Guardamos el precio del modelo
          this.imagenesModelo = data.imagenes_web; // Guardamos las imagenes del modelo
          this.contenidoModelo = data.contenido; // Guardamos el contenido del modelo
          // tslint:disable-next-line:max-line-length
          this.especificacionesTecnicasModelo = Object.keys(data.caracteristicas); // Obtenemos solo las key de las especificaciones tecnicas
          this.detalleEspecificacionesTecnicasModelo = data.caracteristicas; // Obtenemos las caracteristicas tecnicas del modelo
          // tslint:disable-next-line:max-line-length
          const categoriaModelo = data.sub_categoria.toLowerCase().split(' ').join('-'); // Generamos la constante con la categoria del modelo
          // tslint:disable-next-line:max-line-length
          this.enlaceCotizador = 'https://cotizador.yamaha.bo/cotizar/' + categoriaModelo + '/' + this.nombreModelo.toLowerCase().split(' ').join('-'); // Generamos la url del cotizador
      }, error => {
          console.log(error); // Imprimimos en consola el error
      });
      // Verificamos si el modelo es la fz blue core o la fz 2.5 para visualizar las secciones especiales
      if (this.parametroModelo === 'fz-blue-core' || this.parametroModelo === 'fz2.5') {
          this.seccionesEspeciales = true;
      } else {
          this.seccionesEspeciales = false;
      }
  }

  ngOnInit() {
    // Configuramos el swiper en la constante config
      this.config = {
          direction: 'horizontal',
          slidesPerView: 'auto',
          loop: true,
          autoplay: true,
          effect: 'slide', // Efecto por defecto
          speed: 3000,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          }
      };
      this.index = 0; // Inicializamos el indice en la primer posicion
  }
}
