import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ColorearServicesService } from './../Servicios/Colorear/colorear-services.service';

@Component({
  selector: 'app-pintemos-en-casa',
  templateUrl: './pintemos-en-casa.component.html',
  styleUrls: ['./pintemos-en-casa.component.css']
})
export class PintemosEnCasaComponent implements OnInit {
  titulo;
  datos;
  mobile: boolean;
  constructor(private meta: Meta, private servicio: ColorearServicesService) {
    this.onResize();
    this.titulo = 'Pintemos en casa';
    // tslint:disable-next-line:max-line-length
    this.meta.updateTag({name: 'description', content: 'Cotiza tu YAMAHA favorita, TOYOSA S.A Distribuidor oficial de Yamaha en Bolivia.'});
    // tslint:disable-next-line:max-line-length
    this.meta.updateTag({ name: 'keywords', content: 'yamaha, moto, motos, motocicleta, motocicletas, toyota, toyosa, venta, accesorios, precios, cascos, sucursales, bolivia, santa cruz, beni, pando, cochabamba, tarija, potosi, oruro, la paz, sucre, montero, yacuiba, riberalta, puerto suarez'});

    this.servicio.colorear().pipe(first()).subscribe(
      data => {
        this.datos = data;
      }, error => {
        console.log(error);
      }
    );
  }

  onResize() {
    this.mobile = innerWidth < 768
  }

  ngOnInit() {
  }

}
