import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactenosService {

  constructor(private http: HttpClient) { }
  // Servicio que envia el formulario, recibe un objeto con la información de los campos
  enviarFormulario (datos: any) {
    const url = `${environment.base_url}/contactenos`;
    return this.http.post<any>(url, datos);
  }

  // Servicio que obtendra un listado de todas las ciudades
  getCiudad() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/ciudad`;
    return this.http.get<any>(url, httpOptions);
  }

  // Servicio que obtendra un listado de todas las ciudades
  getAsunto() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/asunto/marca/5`;
    return this.http.get<any>(url, httpOptions);
  }
}
