import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgZorroAntdModule, NZ_I18N, es_ES } from 'ng-zorro-antd';
import { LightboxModule } from 'ngx-lightbox';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TarjetasFlotantesComponent } from './tarjetas-flotantes/tarjetas-flotantes.component';
import { ContactenosComponent } from './contactenos/contactenos.component';
import { ComparadorModelosComponent } from './comparador-modelos/comparador-modelos.component';
import { MapaSitioComponent } from './mapa-sitio/mapa-sitio.component';
import { MotosDashboardComponent } from './motos-dashboard/motos-dashboard.component';
import { CategoriaMotosTrabajoComponent } from './categoria-motos-trabajo/categoria-motos-trabajo.component';
import { CategoriaMotosAltaGamaComponent } from './categoria-motos-alta-gama/categoria-motos-alta-gama.component';
import { CategoriaMotosCompetenciaComponent } from './categoria-motos-competencia/categoria-motos-competencia.component';
import { CategoriaMotosCiudadComponent } from './categoria-motos-ciudad/categoria-motos-ciudad.component';
import { CategoriaUtvComponent } from './categoria-utv/categoria-utv.component';
import { SitioConstruccionComponent } from './sitio-construccion/sitio-construccion.component';
import { MantenimientoComponent } from './mantenimiento/mantenimiento.component';
import { TestDriveComponent } from './test-drive/test-drive.component';
import { OfertasComponent } from './ofertas/ofertas.component';
import { LineaFuerzaComponent } from './linea-fuerza/linea-fuerza.component';
import { RepuestosComponent } from './repuestos/repuestos.component';
import { AccesoriosComponent } from './accesorios/accesorios.component';
import { MotosComponent } from './motos/motos.component';
import { CtaMenuComponent } from './cta-menu/cta-menu.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { CtaFlotantesComponent } from './cta-flotantes/cta-flotantes.component';
import { SliderRacingComponent } from './slider-racing/slider-racing.component';
import { CategoriaLfBombasComponent } from './categoria-lf-bombas/categoria-lf-bombas.component';
import { CategoriaLfGeneradoresComponent } from './categoria-lf-generadores/categoria-lf-generadores.component';
import { CategoriaLfMultipropositosComponent } from './categoria-lf-multipropositos/categoria-lf-multipropositos.component';
import { HtecnicasLineafuerzaComponent } from './htecnicas-lineafuerza/htecnicas-lineafuerza.component';
import { CtaMenuLineaFuerzaComponent } from './cta-menu-linea-fuerza/cta-menu-linea-fuerza.component';
import { PintemosEnCasaComponent } from './pintemos-en-casa/pintemos-en-casa.component';
import { AgendarCitaComponent } from './agendar-cita/agendar-cita.component';
import { EnMantenimientoComponent } from './en-mantenimiento/en-mantenimiento.component';

registerLocaleData(es);
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    TarjetasFlotantesComponent,
    ContactenosComponent,
    ComparadorModelosComponent,
    MapaSitioComponent,
    MotosDashboardComponent,
    CategoriaMotosTrabajoComponent,
    CategoriaMotosAltaGamaComponent,
    CategoriaMotosCompetenciaComponent,
    CategoriaMotosCiudadComponent,
    CategoriaUtvComponent,
    SitioConstruccionComponent,
    MantenimientoComponent,
    TestDriveComponent,
    OfertasComponent,
    LineaFuerzaComponent,
    RepuestosComponent,
    AccesoriosComponent,
    MotosComponent,
    CtaMenuComponent,
    SucursalesComponent,
    CtaFlotantesComponent,
    SliderRacingComponent,
    CategoriaLfBombasComponent,
    CategoriaLfGeneradoresComponent,
    CategoriaLfMultipropositosComponent,
    HtecnicasLineafuerzaComponent,
    CtaMenuLineaFuerzaComponent,
    PintemosEnCasaComponent,
    AgendarCitaComponent,
    EnMantenimientoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    LightboxModule,
    SwiperModule,
    JwSocialButtonsModule,
    AppRoutingModule,
    RecaptchaModule.forRoot({
      siteKey: '6LcCEw0TAAAAAKoeyWMIteGc_-6AiOVBa8cUb3ME',
    }),
  ],
  providers: [{ provide: NZ_I18N, useValue: es_ES }],
  bootstrap: [AppComponent]
})
export class AppModule { }
