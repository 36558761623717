import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categoria-lf-multipropositos',
  templateUrl: './categoria-lf-multipropositos.component.html',
  styleUrls: ['./categoria-lf-multipropositos.component.css']
})
export class CategoriaLfMultipropositosComponent implements OnInit {

  // Componente que contiene el listado de Multipropósitos
  titulo;
  constructor() {
    this.titulo = 'Multipropósitos';
  }

  ngOnInit() {
  }
}
