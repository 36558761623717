import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cta-flotantes',
    templateUrl: './cta-flotantes.component.html',
    styleUrls: ['./cta-flotantes.component.css']
})
export class CtaFlotantesComponent implements OnInit {
    // Componente que se encarga de generar el menu flotante con diferentes botones CTA
    icono; // Variable en la cual se cargara la imagen del icono principal de el menu
    elemento; // Constante que contendra al div padre del componente
    efecto; // Constante que contendra a los CTA y se encargara de cambiar la clase para mostrarlos u ocultarlos
    constructor() {
      // Inicializamos la variable icono con la imagen que se mostrara cuando el menu esta oculto
        this.icono = 'principal.svg';
    }
    // Función que se dispara al pasar el mouse sobre el menu
    mostrar() {
        this.icono = 'icono%207.svg';  // Asignamos a la variable icono la imagen que se mostrara cuando el menu este activo
        this.elemento.classList.remove('inactive'); // Removemos la clase inactive del div padre del componente
        this.elemento.classList.add('active'); // Asignamos la clase active al div padre del componente
      // Recorremos la constante efecto y asignamos la clase iconosRevelados para mostrar los demas CTA
        this.efecto.map(index => {
            index.classList.add('iconosRevelados');
        });
    }
    // Función que se dispara al quitar el mouse del menu
    ocultar() {
        this.icono = 'principal.svg'; // Asignamos nuevamente a la variable icono la imagen que se mostrara cuando el menu este inactivo
        this.elemento.classList.remove('active'); // Removemos la clase active del div padre del componente
        this.elemento.classList.add('inactive'); // Asignamos la clase inactive del div padre del componente
        // Recorremos la constante efecto y removemos la clase iconosRevelados para ocultar los demas CTA
        this.efecto.map(index => {
            index.classList.remove('iconosRevelados');
        });
    }
    // Función que se dispara al dar click al div padre del componente, pensado para mobile
    handleMobile() {
      // Vemos si la constante elemento no tiene la clase active
        if (this.elemento.classList[0] !== 'active') {
            this.mostrar(); // Sí, esta oculto disparamos la función para mostrarlos
        } else {
            this.ocultar(); // Caso contrario, esta activo disparamos la función para ocultarlos
        }
    }

    ngOnInit() {
        this.elemento = document.getElementById('cta-flotante'); // Inicializamos la constante elemento con el div padre del componente
        // tslint:disable-next-line:max-line-length
        this.efecto = Array.from(document.getElementsByClassName('efecto')); // Inicializamos la constante con los diferentes elementos que contengan la clase efecto, debemos convertir en un array para poder recorrerlo
    }

}
