import { Component, OnInit } from '@angular/core';
import { MotosCompetenciaService } from '../Servicios/Modelos/motos-competencia.service';

@Component({
  selector: 'app-categoria-motos-competencia',
  templateUrl: './categoria-motos-competencia.component.html',
  styleUrls: ['./categoria-motos-competencia.component.css']
})
export class CategoriaMotosCompetenciaComponent implements OnInit {
  // Componente que contiene el listado de motos de Competencia
  titulo;
  modelos = [];

  constructor(private modelosCompetitionServices: MotosCompetenciaService) {
    this.titulo = 'Motos - Competencia';
    this.modelos.push(this.modelosCompetitionServices.getAllModelsCompetition());
    console.log(this.modelos[0]);
  }

  ngOnInit() {
  }

}
