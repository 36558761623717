import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categoria-lf-bombas',
  templateUrl: './categoria-lf-bombas.component.html',
  styleUrls: ['./categoria-lf-bombas.component.css']
})
export class CategoriaLfBombasComponent implements OnInit {

  // Componente que contiene el listado de Bombas de Agua
  titulo;
  constructor() {
    this.titulo = 'Bombas de Agua';
  }

  ngOnInit() {
  }
}
