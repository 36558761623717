import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MotosCiudadService {

  modelosCiudad = {
    nombre: [
      'FZ FI / FZS FI',
      'T110C',
      'XTZ250',
      'FZ25',
      /*'FZ150',
      'FZN250',*/
      'XTZ125',
      'XTZ150',
      'YBR125G',
      'YBR125ZR'
    ],
    descripcion: [
      ' lorem ipsum',
      't110c lorem ipsum',
      'xt250 lorem ipsum',
      'FZ25 lorem ipsum',
      /*'FZ150 lorem ipsum',
      'FZN250 lorem ipsum',*/
      'xtz125 lorem ipsum',
      'xtz150 lorem ipsum',
      'ybr125g lorem ipsum',
      'ybr125zr lorem ipsum'
    ],
    imagen: [
      'banner-fzfi-fzsfi',
      'banner-t110c',
      'banner-xt250',
      'banner-fz25',
      /*'banner-fz150',
      'banner-fzn250',*/
      'banner-xtz125',
      'banner-xtz150',
      'banner-ybr125g',
      'banner-yb125zr'
    ],
    ruta: [
      '/fz-fi',
      '/t110c',
      '/xt250',
      '/fz25',
      /*'/fz150',
      '/fz250',*/
      '/xtz125',
      '/xtz150',
      '/ybr125g',
      '/ybr125zr'
    ]
  };

  constructor() { }
  // Obtener todos los modelos de motos trabajo
  getAllModelsCity() {
    return this.modelosCiudad;
  }

}
