import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { SitioConstruccionComponent } from './sitio-construccion/sitio-construccion.component';
import { EnMantenimientoComponent } from './en-mantenimiento/en-mantenimiento.component';
import { HomeComponent } from './home/home.component';
import {ContactenosComponent} from './contactenos/contactenos.component';
import {ComparadorModelosComponent} from './comparador-modelos/comparador-modelos.component';
import { MapaSitioComponent } from './mapa-sitio/mapa-sitio.component';
import {MotosDashboardComponent} from './motos-dashboard/motos-dashboard.component';
import {CategoriaMotosTrabajoComponent} from './categoria-motos-trabajo/categoria-motos-trabajo.component';
import {CategoriaMotosCiudadComponent} from './categoria-motos-ciudad/categoria-motos-ciudad.component';
import {CategoriaMotosAltaGamaComponent} from './categoria-motos-alta-gama/categoria-motos-alta-gama.component';
import {CategoriaMotosCompetenciaComponent} from './categoria-motos-competencia/categoria-motos-competencia.component';
import {CategoriaUtvComponent} from './categoria-utv/categoria-utv.component';
import {MantenimientoComponent} from './mantenimiento/mantenimiento.component';
import {TestDriveComponent} from './test-drive/test-drive.component';
import {OfertasComponent} from './ofertas/ofertas.component';
import {LineaFuerzaComponent} from './linea-fuerza/linea-fuerza.component';
import {AccesoriosComponent} from './accesorios/accesorios.component';
import {RepuestosComponent} from './repuestos/repuestos.component';
import {MotosComponent} from './motos/motos.component';
import {SucursalesComponent} from './sucursales/sucursales.component';
import { CategoriaLfBombasComponent } from './categoria-lf-bombas/categoria-lf-bombas.component';
import { CategoriaLfGeneradoresComponent } from './categoria-lf-generadores/categoria-lf-generadores.component';
import { CategoriaLfMultipropositosComponent } from './categoria-lf-multipropositos/categoria-lf-multipropositos.component';
import { HtecnicasLineafuerzaComponent } from './htecnicas-lineafuerza/htecnicas-lineafuerza.component';
import { PintemosEnCasaComponent } from './pintemos-en-casa/pintemos-en-casa.component';
import { AgendarCitaComponent } from './agendar-cita/agendar-cita.component';


// Generar constante con las rutas
const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'motos', component: MotosDashboardComponent},
    {path: 'motos/trabajo', component: CategoriaMotosTrabajoComponent},
    {path: 'motos/trabajo/:modelo', component: MotosComponent},
    {path: 'motos/alta-gama', component: CategoriaMotosAltaGamaComponent},
    {path: 'motos/alta-gama/:modelo', component: MotosComponent},
    {path: 'motos/ciudad', component: CategoriaMotosCiudadComponent},
    {path: 'motos/ciudad/:modelo', component: MotosComponent},
    {path: 'motos/competencia', component: CategoriaMotosCompetenciaComponent},
    {path: 'motos/competencia/:modelo', component: MotosComponent},
    {path: 'atv-utv', component: CategoriaUtvComponent},
    {path: 'atv-utv/:modelo', component: MotosComponent},
    {path: 'linea-de-fuerza', component: LineaFuerzaComponent},
    {path: 'linea-de-fuerza/bombas-de-agua', component: CategoriaLfBombasComponent},
    {path: 'linea-de-fuerza/bombas-de-agua/:modelo', component: HtecnicasLineafuerzaComponent},
    {path: 'linea-de-fuerza/generadores', component: CategoriaLfGeneradoresComponent},
    {path: 'linea-de-fuerza/generadores/:modelo', component: HtecnicasLineafuerzaComponent},
    {path: 'linea-de-fuerza/motores-multipropositos', component: CategoriaLfMultipropositosComponent},
    {path: 'linea-de-fuerza/motores-multipropositos/:modelo', component: HtecnicasLineafuerzaComponent},
    {path: 'contactenos', component: ContactenosComponent},
    {path: 'comparador-modelos', component: ComparadorModelosComponent},
    {path: 'mapa-sitio', component: MapaSitioComponent},
    {path: 'servicio-mantenimiento', component: MantenimientoComponent},
    {path: 'test-drive', component: TestDriveComponent},
    {path: 'ofertas', component: OfertasComponent},
    {path: 'accesorios', component: AccesoriosComponent},
    {path: 'repuestos', component: RepuestosComponent},
    {path: 'sucursales', component: SucursalesComponent},
    {path: 'agendar-cita', component: AgendarCitaComponent},
    {path: 'pintemos-en-casa', component: PintemosEnCasaComponent},
    // si necesitas permisos para el acceso a la ruta canActivate: [AuthGuard]
    // redirigir en caso de que la ruta no sea valida , no exista o no tenga los permisos
    { path: '**', redirectTo: '' }
];
@NgModule({
    exports: [RouterModule],
    imports: [ RouterModule.forRoot(routes) ]
})

export class AppRoutingModule { }
