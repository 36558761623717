import { Component, OnInit } from '@angular/core';
import { MotosaltagamaService } from '../Servicios/Modelos/motosaltagama.service';

@Component({
  selector: 'app-categoria-motos-alta-gama',
  templateUrl: './categoria-motos-alta-gama.component.html',
  styleUrls: ['./categoria-motos-alta-gama.component.css']
})
export class CategoriaMotosAltaGamaComponent implements OnInit {
  // Componente que contiene el listado de motos de alta gama
  titulo;
  modelos = [];

  constructor(private modelosAltaGamaServices: MotosaltagamaService) {
    this.titulo = 'Motos - Alta Gama';
    this.modelos.push(this.modelosAltaGamaServices.getAllModelsAltaGama());
    console.log(this.modelos[0]);
  }
  ngOnInit() {
  }

}
