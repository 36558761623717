import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MotosaltagamaService {
  modelosAltaGama = {
    nombre: [
      'MT03'
    ],
    descripcion: [
      'mt03 lorem ipsum'
    ],
    imagen: [
      'banner-mt03'
    ],
    ruta: [
      '/mt03'
    ]
  };

  constructor() { }
   // Obtener todos los modelos de motos trabajo
  getAllModelsAltaGama() {
    return this.modelosAltaGama;
  }
}
